var UberSource=function(TD, $q){
    this.sources={};
    this.source={};
    
    var settings=
        {
            'VodLocker':
            {
                search:function(str, page)
                {
                    console.log(str);
                    var result=[];
                    url = url_replace({"{searchString}":str,"{page}":page});
                    console.log(url);
                    return request(url).then(function(data)
                    {
                        console.log(data);
                        $(data).find('.box_body .vid_block').each(function( index ) 
                        {
                            [days, views]=$( this ).find('td:nth-child(2)').find('div').not('[class]').first().text().split('|');
                            var res=makeRes
                                (
                                    source.name, 
                                    $( this ).find('a').first().attr('href'), 
                                    $( this ).find('div.link').first().text()
                                );
                            res.poster=$( this ).find('a').first().css('background-image').replace(/^url\(['"]?/,'').replace(/['"]?\)$/,'');
                            res.duration=$( this ).find('span').first().text();
                            res.file=false;
                            res.screen_map=$( this ).find('a').first().css('background-image').replace(/^url\(['"]?/,'').replace(/['"]?\)$/,'').replace(/_t/,'0000');
                            res.views=views.replace(/\D/g, '');
                            res.uploaded=days.replace(/\D/g, '');
                            result.push(res);
                        });
                        return result;
                    });
                },
                page:function(url)
                {
                    console.log(url);
                    return request(url).then(function(data)
                    {
                        var res=makeRes
                        (
                            source.name, 
                            url, 
                            $( data ).find('td#file_title').first().text()
                        );
                        
                        if(data.match(/Video is processing now./)==undefined && data.match(/The file was removed by administrator/)==undefined)
                        {
                            res.poster=data.match(/poster="(.*?)">/)[1];
                        }
                        else
                        {
                            //console.log(data);
                            console.log('Video is processing now.');
                            return false;
                        }
                        res.poster=false;
                        file_url=data.match(/<source src="(.*?)" type="video\/mp4">/)[1]
                        res.file=[];
                        res.file.push({'quality':'360', 'file': file_url });
                        return res;
                    });
                },
            }
            ,'Vk':
            {
                search:function(str, page)
                {
                    var result=[];
                    var deferred = $q.defer();
                    VK.api("video.search", {"q": str, "sort":0, "longer":'600', 'offset':page*source.search_step}, function (data) 
                    {
                        data.response.forEach(function (item) {
                            //ids.push(item.owner_id+'_'+item.id);
                            var res=makeRes
                                (
                                    source.name, 
                                    item.player, 
                                    item.title
                                );
                            res.poster=item.thumb;
                            
                            var date = new Date(null);
                            date.setSeconds(item.duration); // specify value for SECONDS here
                            date.toISOString().substr(11, 8);
                            res.duration=date.toISOString().substr(11, 8);
                            res.file=false;
                            res.screen_map=false;
                            res.views=false;
                            res.uploaded=item.date;
                            result.push(res);
                        });
                        deferred.resolve(result);
                    });
                    return deferred.promise;
                },
                page:function(url)
                {
                    var video_url='https://vk.com/video'+getURLParameter('oid', url)+'_'+getURLParameter('id', url);
                    return request(video_url).then(function(data)
                    {
                        var regex = /url(\d+)":"(.*?)"/gi;
                        var matches=data.match(regex);
                        title=data.match(/"mvData":{"title":"(.*?)",/gi)
                        poster=data.match(/"background-image:url\((.*?)\);/gi)
                        var res=makeRes
                        (
                            source.name, 
                            url, 
                            title[1]
                        );
                        res.poster=poster[1];
                        res.file=[];
                        matches.forEach(function (item) {
                            file_q=item.match(/url(\d+)":/i);
                            file_url=item.match(/":"(.*?)"/i);
                            console.log(file_url[1]);
                            console.log(file_url[1].replace(/\\\//gi, "/"));
                            res.file.push({ 'quality':file_q[1],'file':file_url[1].replace(/\\\//gi, "/") });
                        });
                        return res;
                    });
                },
            }
            ,'NayaVideo':
            {
                search:function(str, page)
                {
                    var result=[];
                    url = url_replace({"{searchString}":str.split(' ').join('.'),"{page}":page}, /\'/gi);
                    console.log(url);
                    console.log(str.split(' ').join('.'));
                    return request(url).then(function(data)
                    {
                        //console.log(data);
                        $(data).find('div.item-video').each(function( index ) 
                        {
                            
                            var res=makeRes
                                (
                                    source.name, 
                                    $( this ).find('a.video-link').first().attr('href'), 
                                    $( this ).find('h2.title').first().text()
                                );
                            res.poster=$( this ).find('a.video-link img.img-responsive').first().attr('src');
                            res.duration=$( this ).find('span.duration').first().text();
                            res.file=false;
                            res.views=$(this).find('div.views-date span').first().text().replace(/\D/g, '');
                            res.uploaded=$(this).find('time.date').first().attr('datetime').replace(/\D/g, '');
                            result.push(res);
                        });
                        return result;
                    });
                },
                page:function(url)
                {
                    console.log(url);
                    return request(url).then(function(data)
                    {
                        var res=makeRes
                        (
                            source.name, 
                            url, 
                            $( data ).find('h1[itemprop=name]').first().text()
                        );
                        
                        res.poster=$(data).find('video').first().attr('poster');
                        res.file=[];
                        res.file.push({'quality':'360', 'file': $(data).find('source').first().attr('src') });
                        return res;
                    });
                },
            }
            ,'Vidto':
            {
                search:function(str, page)
                {
                    return null;
                },
                page:function(url)
                {
                    return request(url).then(function(data)
                    {
                        var send_data=[];
                        var form_data=$( data ).find('input[name=fname]').parent().serialize();
                        var promise = new Promise(function(resolve, reject) {
                            setTimeout(function()
                            {
                                return request(url, 'POST', form_data+'&imhuman=Proceed+to+video').then(function(video_data)
                                {
                                    //console.log(video_data);
                                    var res=makeRes
                                    (
                                        source.name, 
                                        url, 
                                        $( video_data ).find('h2.video-page-head').first().text()
                                    );
                                    
                                    res.poster=$( video_data ).find('a.player-url img').first().attr('src');
                                    res.file=[];
                                    res.file.push({'quality':360, 'file':$( video_data ).find('a.player-url').first().attr('href')});
                                    //return res;
                                    resolve(res);
                                });
                                
                            }, 6000);
                        });
                        return promise.then(function (prom) {
                            return prom;
                        });
                    });
                },
            }
            ,'Vidzi':
            {
                search:function(str, page)
                {
                    return null;
                },
                page:function(url)
                {
                    return request(url).then(function(data)
                    {
                        console.log(data);
                    });
                },
            }
            ,'SubsMovies':
            {
                search:function(str, page)
                {
                    return null;
                },
                page:function(url)
                {
                    return request(url).then(function(data)
                    {
                        //console.log(data);
                        //console.log($( data ).find('iframe').first().attr('data-src'));
                        //return res;
                        return request($( data ).find('iframe').first().attr('data-src')).then(function(video_data)
                        {
                            //console.log(video_data);
                            //console.log($( video_data ).find('source').first().attr('src'));
                            var res=makeRes
                            (
                                source.name, 
                                url, 
                                ''
                            );
                            
                            //res.poster=$( video_data ).find('a.player-url img').first().attr('src');
                            res.file=[];
                            res.file.push({'quality':480, 'file':$( video_data ).find('source').first().attr('src')});
                            return res;
                        });
                    });
                },
            }
            ,'OpenSubtitles':
            {
                search:function(str, season, ep, lang_id)
                {
                    var result=[];
                    url = url_replace({"{serial_name}":str,"{season}":season,"{ep}":ep,"{lang_id}":lang_id});
                    console.log(url);
                    return request(url).then(function(data)
                    {
                        if($(data).has('table#search_results tr[id*=name]').length)
                        {
                            $(data).find('table#search_results tr[id*=name]').each(function( index ) 
                            {
                                var id=$( this ).attr('id').replace(/\D/g, '');
                                title_container=$(this).find('td#main'+id).first().html().split('<br>')[1].toString().split('\n');
                                if(title_container[2]!==undefined)
                                {
                                    title=title_container[2];
                                } 
                                else if(title_container[0].length!=0)
                                {
                                    title=title_container[0];
                                }
                                else if(title_container[0].length==0)
                                {
                                    title=$(this).find('td#main'+id).find('strong a').first().text();
                                }
                                title=title.replace(/(<([^>]+)>)/ig,"");
                                //title=title_container;
                                var file ="http://dl.opensubtitles.org/en/download/sub/vrf-"+id.toString(16)+"/"+id;
                                
                                var res=makeRes
                                (
                                    source.name, 
                                    'http://www.opensubtitles.org'+$( this ).find('td strong a').first().attr('href'), 
                                    title
                                )
                                res.uploded=$( this ).find('time').first().attr('datetime');
                                res.lang=$( this ).find('div.flag').first().parent().attr('title');
                                res.file=file;
                                result.push(res);
                            });
                        }
                        else
                        {
                            return settings[source.name].page_process(data);
                        }
                        return result;
                    });
                },
                page:function(url)
                {
                    return request(url).then(function(data)
                    {
                        return settings[source.name].page_process(data);
                    });
                },
                page_process:function(data)
                {
                    //console.log($(data).find('img[title*=Subtitle]').first());
                    var id=$(data).find('a[title=Download]').first().attr('href').replace(/\D/g, '');
                    var res=makeRes
                    (
                        source.name, 
                        'http://www.opensubtitles.org/en/subtitles/'+id, 
                        $(data).find('img[title*="Subtitle filename"]').first().parent().text().replace(/(<([^>]+)>)/ig,"").split('.srt')[0]
                    )
                    res.uploded=$( data ).find('time').first().attr('datetime');
                    res.lang=$( data ).find('a[title*="All subtitles for this movie in this language"]').first().attr('title').match(/- (.*?) \(/i)[1];
                    //.match(/size (.*?)\,/i)[1]
                    res.file="http://dl.opensubtitles.org/en/download/sub/vrf-"+id.toString(16)+"/"+id;;
                    return res;
                    /*
                    list($title, $trash)=explode('.srt', $html->find('img[title*=Subtitle filename]', 0)->parent()->plaintext, 2);
                    $lang=Mate::match('- [*] (', $html->find('a[title*=All subtitles for this movie in this language]', 0)->attr['title']);
                    $id=preg_replace('/[^0-9]/', '', $html->find('a[title=Download]', 0)->attr['href']);
                    $subtitle=Search::makeRes
                        (
                            'OpenSubtitles', 
                            $url, 
                            $title,
                            $html->find('time', 0)->attr['datetime'], 
                            $lang,
                            $file="http://dl.opensubtitles.org/en/download/sub/vrf-".dechex($id)."/".$id
                        );
                    //*/
                },
            }
            ,'PirateBay':
            {
                search:function(str, page)
                {
                    var result=[];
                    url = url_replace({"{searchString}":str,"{page}":page});
                    console.log(url);
                    return request(url).then(function(data)
                    {
                        /* maybe some day
                        $(data).find('.results tr').each(function( index ) 
                        {
                            console.log($(this));
                            if($(this).has("a[href*=torrent]"))
                            {
                                console.log($(this).find('a[href*=torrent]').first().attr('href'));
                                console.log($(this).find('a[href*=magnet]').first().attr('href'));
                                var size=$(this).find('i').first().text().match(/Size (.*?)\,/i)[1];
                                var res=makeRes
                                (
                                    source.name, 
                                    'https://thepiratebay.org'+$(this).find('a[href*=torrent]').first().attr('href'), 
                                    $(this).find('a[href*=torrent]').first().text()
                                )
                                res.magnet=$(this).find('a[href*=magnet]').first().attr('href');
                                res.size=size;
                                res.seed=$(this).find('td:nth-child(1)').text();
                                res.leech=$(this).find('td:nth-child(2)').text();
                                res.lang=false;
                                result.push(res);
                            }
                        });
                        //*/
                        $(data).find('table#searchResult tr:has(td)').each(function( index ) 
                        {
                            if($(this).has("td:nth-child(2)"))
                            {
                                //var size=$(this).find('td:nth-child(2) .detDesc').first().text().match(/size (.*?)\,/i)[1];
                                var res=makeRes
                                (
                                    source.name, 
                                    'https://thepiratebay.org'+$(this).find('a[href*=torrent]').first().attr('href'), 
                                    $(this).find('a[href*=torrent]').first().text()
                                )
                                res.magnet=$(this).find('a[href*=magnet]').first().attr('href');
                                res.size=$(this).find('td:nth-child(5)').first().text();
                                res.seed=$(this).find('td:nth-child(6)').first().text();
                                res.leech=$(this).find('td:nth-child(7)').first().text();
                                res.lang=false;
                                result.push(res);
                            }
                        });
                        return result;
                    });
                },
                page:function(url)
                {
                    return request(url).then(function(data)
                    {
                        var res=makeRes
                        (
                            source.name, 
                            $(data).find('div#title a').first().attr('href'), 
                            $(data).find('div#title').first().text()
                        )
                        var size=$(data).find('dl.col1 dd:eq(2)').text().replace(/\((.*?)\)/i, '');
                        res.size=size;
                        res.magnet=$(data).find('a[href*=magnet]').first().attr('href');
                        res.lang=false;
                        $(data).find('dl.col2 dt').each(function( index, obj )
                        {
                            switch ($(this).text()) {
                                case 'Seeders:':
                                    res.seed=$(data).find('dl.col2 dd:eq('+index+')').text();
                                break;
                                case 'Leechers:':
                                    res.leechs=$(data).find('dl.col2 dd:eq('+(index)+')').text();
                                break;
                                case 'By:':
                                    res.user=$(data).find('dl.col2 dd:eq('+(index)+')').text();
                                break;
                            }
                        });
                        return res;
                    });
                },
            }
            ,'ExtraTorrent':
            {
                search:function(str, page)
                {
                    var result=[];
                    url = url_replace({"{searchString}":str,"{page}":page});
                    return request(url).then(function(data)
                    {
                        $(data).find('table.tl tbody tr.tlz,table.tl tbody tr.tlr').each(function( index ) 
                        {
                            if($(this).has("td a:eq(1)"))
                            {
                                var res=makeRes
                                (
                                    source.name, 
                                    'http://extratorrent.cc'+$(this).find('td a[title*=torrent]:eq(1)').attr('href'), 
                                    $(this).find('a[title*=torrent]:eq(1)').text()
                                )
                                res.magnet='http://extratorrent.cc'+$(this).find('td a[href*=torrent]').first().attr('href');
                                res.size=$(this).find('td:eq(-4)').text();
                                res.seed=$(this).find('td.sn,td.sy').first().text();
                                res.leech=$(this).find('td.ln,td.ly').first().text();
                                res.lang=false;
                                result.push(res);
                            }
                        });
                        return result;
                    });
                },
                page:function(url)
                {
                    return request(url).then(function(data)
                    {
                        
                    });
                },
            }
            ,'Eztv':
            {
                search:function(str, page)
                {
                    var result=[];
                    url = url_replace({"{searchString}":str,"{page}":page});
                    return request(url).then(function(data)
                    {
                        $(data).find('table.forum_header_border tr.forum_header_border').each(function( index ) 
                        {
                            var res=makeRes
                            (
                                source.name, 
                                'https://eztv.ag'+$(this).find('td.forum_thread_post:eq(1)').find('a').first().attr('href'), 
                                $(this).find('a.epinfo').first().text()
                            )
                            res.magnet=$(this).find('a[href*=magnet]').first().attr('href');
                            res.size=$(this).find('td:eq(3)').text();
                            res.seed=false;
                            res.leech=false;
                            res.lang=false;
                            result.push(res);
                        });
                        return result;
                    });
                },
                page:function(url)
                {
                    return request(url).then(function(data)
                    {
                        
                    });
                },
            }
            ,'KickassTorrents':
            {
                search:function(str, page)
                {
                    var result=[];
                    url = url_replace({"{searchString}":str,"{page}":page});
                    return request(url).then(function(data)
                    {
                        $(data).find('tr.odd , tr.even').each(function( index ) 
                        {
                            var res=makeRes
                            (
                                source.name, 
                                'https://kat.cr'+$(this).find('a.cellMainLink').first().attr('href'), 
                                $(this).find('a.cellMainLink').first().text()
                            )
                            res.magnet=$(this).find('a[href*=magnet]').first().attr('href');
                            res.size=$(this).find('td:eq(1)').text();
                            res.seed=$(this).find('td.green').first().text();
                            res.leech=$(this).find('td.red').first().text();
                            res.lang=false;
                            result.push(res);
                        });
                        return result;
                    });
                },
                page:function(url)
                {
                    return request(url).then(function(data)
                    {
                        
                    });
                },
            }
            ,'TorrentDownloads':
            {
                search:function(str, page)
                {
                    var result=[];
                    url = url_replace({"{searchString}":str,"{page}":page});
                    console.log(url);
                    return request(url).then(function(data)
                    {
                        $(data).find('div.grey_bar3').each(function( index ) 
                        {
                            if($(this).has("a[title*=torrent]") && !ValidURL($(this).find('a[title*=torrent]').first().attr('href')))
                            {
                                var res=makeRes
                                (
                                    source.name, 
                                    'https://www.torrentdownloads.me'+$(this).find('a[title*=torrent]').first().attr('href'), 
                                    $(this).find('a[title*=torrent]').first().text()
                                )
                                res.magnet='https://www.torrentdownloads.me'+$(this).find('a[title*=torrent]').first().attr('href');
                                res.lang=false;
                                //console.log($(this).find('span:not([class])'));
                                $(this).find('span:not([class])').each(function( index, item ) 
                                {
                                    switch(index) {
                                        case 0:  
                                            res.leech=$(item).text();
                                            break;
                                        case 1:
                                            res.seed=$(item).text();
                                            break;
                                        case 2:
                                            res.size=$(item).text();
                                            break;
                                    }
                                });
                                result.push(res);
                            }
                        });
                        return result;
                    });
                },
                page:function(url)
                {
                    return request(url).then(function(data)
                    {
                        
                    });
                },
            }
            ,'Tracker1337x':
            {
                search:function(str, page)
                {
                    var result=[];
                    url = url_replace({"{searchString}":str,"{page}":page});
                    //console.log(url);
                    return request(url).then(function(data)
                    {
                        //console.log(data);
                        $(data).find('.table-list.table.table-responsive.table-striped tr:gt(0)').each(function( index ) 
                        {
                            if($(this).has("td.coll-1.name"))
                            {
                                var res=makeRes
                                (
                                    source.name, 
                                    'http://1337x.to'+$(this).find('b.highlight').first().parent().attr('href'), 
                                    $(this).find('b.highlight').first().parent().text()
                                )
                                res.magnet='http://1337x.to'+$(this).find('b.highlight').first().parent().attr('href');
                                res.size=$(this).find('td.size').first().text();
                                res.seed=$(this).find('td.seed').first().text();
                                res.leech=$(this).find('td.leeches').first().text();
                                res.lang=false;
                                result.push(res);
                            }
                        });
                        return result;
                    });
                },
                page:function(url)
                {
                    return request(url).then(function(data)
                    {
                        
                    });
                },
            }
        };
    
    url_replace=function(rep, clean=/\.|\'/gi) 
    {
        var url=this.source.search_page;
        this.source.search_page.match(/{(.*?)}/gi).forEach(function(item, i) {
            //console.log(rep[item].toString().toLowerCase().replace(/\.\'/g,'').replace(/\s/g,'+'));
            //console.log(encodeURIComponent(rep[item].toString().toLowerCase().replace(/\.\'/g,'').replace(/\s/g,'+')));
            //console.log(rep[item].toString().toLowerCase().replace(/\./g,''));
            url=url.replace(new RegExp(item,"gi"), encodeURIComponent(rep[item].toString().toLowerCase().replace(clean,'')));
        });
        return url;
    };
        
    request=function(url, method='GET', data=[]) 
    {
        pluginId=$('.incoming').attr('tpl-get');
        var deferred = $q.defer();
        chrome.runtime
            .sendMessage
                (
                    pluginId, 
                    {
                        method: method,
                        action: 'xhttp',
                        send_array:data,
                        url:url
                    },
                    function(responseText) 
                    {
                        deferred.resolve(responseText);
                    }
                );
        return deferred.promise;
    };
        
    setSource=function(source) 
    {
        this.source=source;
        return this;
    };
    
    makeRes=function($source, $url, $title) {
        var obj = 
        {
            source:$source,
            url:$url,
            title:$title,
        };
        return obj;
    };
    
    //vodlocker and torrents
    search=function(str, page)
    {
        return settings[source.name].search(str, page);
    };
    
    //opensubtitles
    search=function(str, season, ep, lang_id)
    {
        return settings[source.name].search(str, season, ep, lang_id);
    };
    
    page=function(url)
    {
        return settings[source.name].page(url);
    };
    
    function getURLParameter(name, url) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [null, ''])[1].replace(/\+/g, '%20')) || null;
    }
    
    return {
        setSource: setSource,
        page: page,
        search: search
    };
};
SovTor.service('UberSource', UberSource);

function ValidURL(str) {
    var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    return regex.test(str);
}
/*
//(function() {
    var UberSource=function(source){
        
        //var pluginId=$('.incoming').attr('tpl-get');
        this.source=source;
        
        url_replace=function(str, page, lang=false) 
        {
            search_url = source.search_page.replace(/{searchString}/gi, encodeURIComponent(str.replace(/\./g,'')));
            console.log(search_url);
            return search_url.replace(/{page}/gi, page*source.search_step);
        };
        
        request=function(url, callbackFn) 
        {
            var pluginId=$('.incoming').attr('tpl-get');
            console.log(pluginId);
            chrome.runtime
                .sendMessage
                    (
                        pluginId, 
                        {
                            method: 'GET',
                            action: 'xhttp',
                            url:url
                        },
                        function(responseText) 
                        {
                            callbackFn(responseText);
                        }
                    );
        };
        return {
            request: request,
            url_replace: url_replace
        }
    };
    //SovTor.service('UberSource', UberSource);
    //*
    var result=new UberSource(config.source.VodLocker).search('searil name');
    
    var VodLocker = new UberSource('VodLocker');
    VodLocker.search=function(str)
    {
        var result=[];
        url = this.url_replace(str, 1);
        this.request(url, function(data){
            $(data).find('table.vlist tr').each(function( index ) 
            {
                [days, views]=$( this ).find('td:nth-child(2)').find('div').not('[class]').first().text().split('|');
                result.push(p.makeRes
                    (
                        p.Source.name, 
                        $( this ).find('a').first().attr('href'), 
                        $( this ).find('div.link').first().text(), 
                        $( this ).find('a').first().css('background-image').replace(/^url\(['"]?/,'').replace(/['"]?\)$/,''), 
                        $( this ).find('span').first().text(), 
                        false, 
                        $( this ).find('a').first().css('background-image').replace(/^url\(['"]?/,'').replace(/['"]?\)$/,'').replace(/_t/,'0000'), 
                        views.replace(/\D/g, ''), 
                        days.replace(/\D/g, '')
                    ));
            });
        });
        console.log(result);
        return result;
    };
    console.log(VodLocker.search('big bang theory'));
    //SovTor.service('VodLocker', new UberSource('VodLocker'));
    //*/
//}());
//module.exports = UberSource;
/*
var UberSource = function (data) {

    var db = {};

    var get = function (id) {
        console.log('Getting task ' + id);
        return {
            name: 'new task from db'
        }
    }

    var save = function (task) {
        console.log('Saving ' + task.name + ' to the db');
    }
    
    
    return {
        get: get,
        save: save
    }


}
//*/