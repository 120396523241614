//*
var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
var myPlayer = false;
var plugin = false;
function video_init() 
{
    var pl=$('#video_player');
    if(pl.length>0 && pl.attr('data-url')!=undefined)
    {
        if(!myPlayer)
        {
            var playaa=videojs("video_player", 
                    { 
                        "controls": true, 
                        "autoplay": false, 
                        "preload": "auto",
                        plugins: 
                        {
                            videoJsResolutionSwitcher: 
                            {
                                default: 'high',
                                dynamicLabel: true
                            }
                        }
                    }
                )
            .src([
                { type: "video/mp4", src: pl.attr('data-url') },
            ])
            .ready(function(){
                myPlayer = this;
                myPlayer.poster(pl.attr('data-img'));
                myPlayer.hotkeys({
                    volumeStep: 0.05,
                    seekStep: 5
                });
                myPlayer.on('resolutionchange', function(){
                    console.info('Source changed to %s', myPlayer.src())
                })
                /*
                MyVideo.controlBar.addChild('button', 
                {
                    'el':videojs.createEl('button', { className:  'vjs-hd-button vjs-control',
                          'role': 'button'})
                });
                //*/
                //*
                var tracks=myPlayer.remoteTextTracks();
                tracks.tracks_.forEach(function(track, i) 
                {
                    myPlayer.removeRemoteTextTrack(track);
                });
                
                var div=angular.element('div[key=ep]');
                //console.log(div.attr('tpl-get'));
                var ep = JSON.parse(div.attr('tpl-get'));
                
                $http = angular.injector(["ng"]).get("$http");
                
                var div=angular.element('a[key=user]');
                //console.log(div.attr('tpl-get'));
                var user = JSON.parse(div.attr('tpl-get'));
                if(user.id)
                {
                    myPlayer.on('ended', function() {
                        angular.element(document.getElementById("user")).scope().watchEp(ep.id);
                        angular.element(document.getElementById("user")).scope().videoSend(angular.element(document.getElementById("VideoCtrl")).scope().video);
                    });
                }
                /*
                $http({
                    method: 'GET',
                    url: "/ep/"+ep.id+"/"+$('.video_subtitle_lang').val().replace(/\D/g,'')+"/subtitle/vtt"
                })
                .then(function successCallback(response) 
                {
                    response.data.forEach(function(item, i) 
                    {
                        myPlayer.addRemoteTextTrack({
                            'src':item.vtt,
                            'kind':'captions',
                            'srclang':item.lang.short,
                            'label':item.lang.short + ' ' + item.title
                        });
                    });
                });
                //*/
            });
        }
    }
}

var SovTor = angular.module('SovTor', ['ngMaterial','ngRoute', 'angular.filter', 'mantisTplGet', 'infinite-scroll', 'LocalStorageModule']);

SovTor.config(function($interpolateProvider, $locationProvider, $routeProvider, $compileProvider, $httpProvider, $mdDateLocaleProvider) 
{
    $mdDateLocaleProvider.formatDate = function(date) {
        return moment(date).format('YYYY-MM-DD');
    };
    $mdDateLocaleProvider.parseDate = function(dateString) {
        console.log(dateString);
        var m = moment(dateString, 'YYYY-MM-DD', true);
        return m.isValid() ? m.toDate() : new Date(NaN);
    };
    
    if (history.pushState) {
        $locationProvider.html5Mode({
          enabled: true,
          requireBase: false,
          rewriteLinks: false
        });
    }
    $httpProvider.interceptors.push(function() {
        return {
            response: function(res) {
                return res;
            },
            responseError: function(res) {
                if (res.status == 401){
                    window.location = "/register";
                }
                //console.log('some');
                return;
            }
        };
    });

    //$interpolateProvider.startSymbol('@{{');
    //$interpolateProvider.endSymbol('}}');
    /*
    $routeProvider.
    when('/ep/date', 
    {
        //templateUrl: 'ep.html',
        controller: 'DateCtrl'
    });
    //*/
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|magnet):/);
});
/**
 * Created by Viktor on 06.04.15.
 */

/**
 * Just get css link on page and insert in javascript source this file to turn on the scroll top button.
 * If need other css class you can reinit on page ScrollTop.init('newClass').
 *
 * @type {{oldDefaults: Array, init: init, declareEvents: declareEvents}}
 */
ScrollTop = {

    /**
     * var for save old option to destroy button if init redeclare
     */
    oldDefaults: [],

    /**
     * Var for css class of w-watch toggle
     */
    wWatchClass: null,

    /**
     * Method to init ScrollTop
     *
     * @param options
     */
    init: function (options) {

        // insert button in html DOM
        $('body')
            .append('<div class="scroll-to-top up"><span class="vertical-text">To Top</span></div>')
            //.append('<a class="scroll-to-top up">To Top</a>')
            ;
        if ( this.oldDefaults.length ) {
            // delete old button
            $('.' + this.oldDefaults['class']).remove();
        }

        var defaults = {
            /**
             *  Option for class of scroll button
             */
            class: 'scroll-to-top',
            showBelow: 'left_col',
            mainContent: 'main_col',

            /**
             * Class for w watch toggle. Needs to change position of button
             */
            wWatchClass: 'last_w_toggle'
        }
        $.extend(defaults, options);
        $('.scroll-to-top').hide();
        this.wWatchClass = defaults['wWatchClass'];
        //save defaults
        this.oldDefaults = defaults;
        this.showBelow = defaults['showBelow'];
        this.mainContent = defaults['mainContent'];
        this.declareEvents(defaults['class']);
    },

    /**
     * Add events
     *
     * @param cssClass
     */
    declareEvents: function(cssClass) {
        var self = this;
        cssClass = '.' + cssClass;
        bellow = $('.'+self.showBelow);
        mainContent = $('.'+self.mainContent);
        
        // Event scroll
        $(window).scroll(function () {
            bottom = bellow.position().top + bellow.outerHeight(true);
            //console.log(parseInt($(cssClass).width())<parseInt(mainContent.position().left));
            //console.log(parseInt($(cssClass).width()));
            //console.log(parseInt(mainContent.position().left));
            if ($(this).scrollTop() > bottom && (parseInt($(cssClass).width())+10)<parseInt(mainContent.position().left))
            {
                $(cssClass).fadeIn(200);
                //console.log('show');
                $(cssClass).css('width', parseInt(mainContent.position().left));
            }
            else if($(this).scrollTop() < bottom && $(cssClass).is(":visible") && parseInt($(cssClass).width())>(parseInt(mainContent.position().left))-50) 
            {
                $(cssClass).css('width', '2rem');
                //console.log('shrink');
                //$(cssClass).fadeOut(200);
            }

            // if w-watch opened move button to top
            if ($('.' + self.wWatchClass).hasClass('open')) {
                $(cssClass).css('margin-bottom', '150px');
            } else {
                $(cssClass).css('margin-bottom', '25px');
            }
        });

        // Event click
        $(cssClass).click(function () {
            var doc = document.documentElement;
            var left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
            var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
            if(top > 300)
            {
                localStorage.setItem('old_top', top);
                $('html, body').animate({scrollTop: 0}, 700);
            }
            else
            {
                //console.log(top);
                old_top=localStorage.getItem('old_top');
                //console.log(old_top);
                $('html, body').animate({scrollTop: old_top}, 200);
            }
            
        });

        // Event for w-watch click
        $('.' + self.wWatchClass).click(function(){
            if ($(this).hasClass('open')) {
                $(cssClass).css('margin-bottom', '25px');
            } else {
                $(cssClass).css('margin-bottom', '150px');
            }
        });
    }
}

$(function(){
    ScrollTop.init();
});
/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

/* 
require('./bootstrap');
Vue.component('example', require('./components/Example.vue'));

const app = new Vue({
    el: '#app'
});
*/