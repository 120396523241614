SovTor.service('torrentService', function(TD) {
    var torrents = [];
    TD.get('torrent').then(function(data)
    {
        torrents=data;
    });

    var addTorrent = function(torrent) 
    {
        torrents.push(torrent);
    };

    var getTorrents = function()
    {
        return torrents;
    };

    return {
        addTorrent: addTorrent,
        getTorrents: getTorrents
    };

});