SovTor.filter('onlyNumbers', function () {
    return function (item) {
        return item.replace(/[^\d|^,|^.]/g, '');
    }
});

SovTor.filter('extIcon', function () {
    return function (item) {
        switch(parseInt(item))
            {
                case 1:
                    return 'fa-vk';
                    break;
                case 2:
                    console.log(item);
                    return 'fa-instagram';
                    break;
                case 3:
                    return 'fa-twitter-square';
                    break;
                case 4:
                    return 'fa-facebook-square';
                    break;
                
            }
    }
});

SovTor.filter('strpad', function () {
    return function (item) {
        //console.log(str_pad(item, 2, '0', 'STR_PAD_LEFT'));
        return padLeft(item, 2);
    }
});

SovTor.filter('airDate', function () {
    return function (item) {
        return item.replace(/[^\d|^,|^.]/g, '');
    }
});

SovTor.filter('dayMonth', function () {
    return function (item) {
        //return item.replace(/[^\d|^,|^.]/g, '');
        return moment(item).format('MM.DD')
    }
});

SovTor.filter('torrentFilter', function ($filter) {
    return function (item) {
        //console.log(item);
        return $filter('orderBy')($filter('unique')(item, 'quality.id'), 'seed', true);
        // /| unique: 'quality.id' | orderBy:'quality.group_id':true 
    }
});

SovTor.filter('find', function() {
    return function(val, propertyName, collection, resp='None') {
        obj={};
        obj[propertyName]=val;
        var data=_.findWhere(collection, obj);
        if(_.has(data, 'name'))
        {
            return data.name;
        }
        return resp;
    }
});

SovTor.filter( 'filesize', function () {
  var units = [
    'bytes',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB'
  ];

  return function( bytes, precision ) {
    if ( isNaN( parseFloat( bytes )) || ! isFinite( bytes ) ) {
      return '?';
    }

    var unit = 0;

    while ( bytes >= 1024 ) {
      bytes /= 1024;
      unit ++;
    }

    return bytes.toFixed( + precision ) + ' ' + units[ unit ];
  };
});

function padLeft(nr, n, str){
    return Array(n-String(nr).length+1).join(str||'0')+nr;
}
//or as a Number prototype method:
Number.prototype.padLeft = function (n,str){
    return Array(n-String(this).length+1).join(str||'0')+this;
}
