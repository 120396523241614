SovTor.factory('userFa', function($http) {
    var httpRequest;
    var userFa = 
    {
        videoSend: function(video) 
        {
            if ( !httpRequest ) 
            {
                console.log(video);
                httpRequest = $http({
                    method: "POST",
                    data: video,
                    url: "/video"
                })
                .then(function successCallback(response) 
                {
                    //$mdToast.show($mdToast.simple().textContent(response.data.message));
                }, 
                function errorCallback(response) 
                {
                    //$scope.error=response.data;
                });
                
            }
            // Return the promise to the controller
            return httpRequest;
        },
        videoDelete: function(video) 
        {
            if ( !httpRequest ) 
            {
                console.log(video);
                httpRequest = $http({
                    method: "POST",
                    data: {'url':video},
                    url: "/video/delete"
                })
                .then(function successCallback(response) 
                {
                    //$mdToast.show($mdToast.simple().textContent(response.data.message));
                }, 
                function errorCallback(response) 
                {
                    //$scope.error=response.data;
                });
                
            }
            // Return the promise to the controller
            return httpRequest;
        }
    };
    return userFa;
});