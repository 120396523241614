

function getAllUrlParams(url) {

    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {

    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i=0; i<arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // in case params look like: list[]=thing1&list[]=thing2
      var paramNum = undefined;
      var paramName = a[0].replace(/\[\d*\]/, function(v) {
        paramNum = v.slice(1,-1);
        return '';
      });

      // set parameter value (use 'true' if empty)
      var paramValue = typeof(a[1])==='undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      paramValue = paramValue.toLowerCase();

      // if parameter name already exists
      if (obj[paramName]) {
        // convert value to array (if still string)
        if (typeof obj[paramName] === 'string') {
          obj[paramName] = [obj[paramName]];
        }
        // if no array index number specified...
        if (typeof paramNum === 'undefined') {
          // put the value on the end of the array
          obj[paramName].push(paramValue);
        }
        // if array index number specified...
        else {
          // put the value at that index number
          obj[paramName][paramNum] = paramValue;
        }
      }
      // if param name doesn't exist yet, set it
      else {
        obj[paramName] = paramValue;
      }
    }
    }

    return obj;
    }

SovTor.controller('ConfigCtrl', function($scope, $http, TD) 
{
    var httpRequest = $http({
        method: 'GET',
        cache: true,
        headers: {'Content-Type': 'application/json'},
        url: '/data/config'
    })
    .then(function successCallback(response) 
    {
        $scope.config=response.data;
    }, 
    function errorCallback(response) 
    {
        console.log(response);
    });
    
});

SovTor.controller('FormCtrl', function($scope, $parse, $http, TD, $mdToast) 
{
    
    $scope.send = function(e, callback=false) 
    {
        e.preventDefault();
        var form=angular.element(e.target);
        var data={}
        form.find('input, textarea, select').each(function (idx, input) {
            el=angular.element(input);
            if(el.attr('ng-model'))
            {
                var model_data=$parse('$parent.'+el.attr('ng-model'));
                data[el.attr('name')]=model_data($scope);
            }
            else
            {
                data[el.attr('name')]=el.val();
            }
        });
        //*
        //console.log(data);
        var httpRequest = $http({
            method: form.attr('method'),
            data: data,
            url: form.attr('action')
        })
        .then(function successCallback(response) 
        {
            $scope.error='';
            if(response.data.url)
            {
               window.location = response.data.url; 
            }
            if(response.data.message)
            {
                $mdToast.show($mdToast.simple().textContent(response.data.message));
            }
            if(callback)
            {
                callback(response.data);
            }
        }, 
        function errorCallback(response) 
        {
            $scope.error=response.data;
        });
        //*/
        
    };
    
});

SovTor.controller('UserCtrl', function($scope, $http, TD, $mdToast) 
{
    $scope.user=false;
    $scope.edit=false;
    
    TD.get('user').then(function(data)
    {
        $scope.user=data;
        console.log($scope.user);
    });
    
    $scope.editProfile = function(data)
    {
        console.log($scope.edit);
        $scope.edit=!$scope.edit;
        if(data)
        {
            $scope.user=data.user;
        }
    }
    
    $scope.subscribe = function(id, remove=false)
    {
        if(!remove)
        {
            var httpRequest = $http({
                method: "POST",
                //data: data,
                url: "/serial/"+id+"/sub"
            })
            .then(function successCallback(response) 
            {
                $scope.user.serial.push(response.data.serial);
                $mdToast.show($mdToast.simple().textContent(response.data.message));
            }, 
            function errorCallback(response) 
            {
                $scope.error=response.data;
            });
        }
        else
        {
            var httpRequest = $http({
                method: "DELETE",
                url: "/serial/"+id+"/sub"
            })
            .then(function successCallback(response) 
            {
                var index = $scope.user.serial.indexOf(response.data.serial);
                $scope.user.serial.splice(index, 1);
                $mdToast.show($mdToast.simple().textContent(response.data.message));
            }, 
            function errorCallback(response) 
            {
                $scope.error=response.data;
            });
        }
    }
        
    $scope.watchEp = function(id)
    {
        var httpRequest = $http({
            method: "POST",
            //data: data,
            url: "/ep/"+id+"/watch"
        })
        .then(function successCallback(response) 
        {
            var index = $scope.user.ep.indexOf(response.data.ep);
            if(index != -1)
            {
                console.log(index);
                $scope.user.ep.splice(index, 1);
            }
            else
            {
                console.log(index);
                $scope.user.ep.push(response.data.ep);
            }
            $mdToast.show($mdToast.simple().textContent(response.data.message));
        }, 
        function errorCallback(response) 
        {
            $scope.error=response.data;
        });
    }
    
    $scope.serialEdit = function(serial)
    {
        serial.edit=!serial.edit;
    }
    
    $scope.videoSend = function(video)
    {
        console.log(video);
        var httpRequest = $http({
            method: "POST",
            data: video,
            url: "/video"
        })
        .then(function successCallback(response) 
        {
            $mdToast.show($mdToast.simple().textContent(response.data.message));
        }, 
        function errorCallback(response) 
        {
            $scope.error=response.data;
        });
    }
    
});

SovTor.controller('EpsCtrl', function($scope, $http, TD, $filter, localStorageService) 
{
    $scope.date=moment().subtract(1, 'days').format("YYYY-MM-DD");
    $scope.step=1;
    $scope.end=false;
    $scope.loading=false;
    
    TD.get('user').then(function(data)
    {
        $scope.user=data;
        console.log($scope.user);
    });
    
    TD.get('eps').then(function(data)
    {
        //console.log(data);
        $scope.eps=data;
    });
    
    TD.get('future_eps').then(function(data)
    {
        $scope.future_eps=data;
    });
    
    $scope.future_eps=[];
    
    $scope.isDateInFuture=function(item)
    {
        var now = moment();
        var date = moment(item.airdate);
        if(date>now)
        {
            if ($scope.future_eps.indexOf(item) == -1) {
                $scope.future_eps.push(item);
            }
            return false;
        }
        return true;   
    }
    
    $scope.loadEps = function(step=0) 
    {
        if(!$scope.end && !$scope.loading)
        {
            $scope.loading=true;
            var date=moment($scope.date).subtract($scope.step, 'days').format("YYYY-MM-DD");
            $http.get('/ep/date/'+date)
            .then(function successCallback(resp) 
            {
                
                if(resp.data.data.eps.length>0)
                {
                    $scope.eps = $scope.eps.concat(resp.data.data.eps);
                    //$scope.offset+=data.posts.length;
                }
                $scope.date=date;
                $scope.loading=false;
            }, 
            function errorCallback(response) 
            {
                $scope.error=response.data;
            });
            
        }
    };
    

});

SovTor.controller('VideoCtrl', function($scope, $http, TD, UberSource, userFa) 
{
    $scope.send = {
                source: 17,
                lang: 1,
                page: 0,
            };
    
    $scope.video_filter = false;
    $scope.startPlay = '';

    $scope.video =  false;
    $scope.videos =  [];
    $scope.search = function($send, $id, $videos=[]) 
    {
        if($send.source==16)
        {
            VK.Auth.login(function(response) 
            {
                source =_.findWhere($scope.config.source, {id: $send.source});
                UberSource.setSource(source.name).search($('.serial-title').text(), $send.page)
                    .then(function(data)
                    {
                        console.log(data);
                        $scope.videos=$videos.concat(data);
                        $scope.templateURL='video_search_result.tpl';
                    });
            }, 4785175);
            /*
            VK.Auth.login(function(response) 
            {   
                console.log(response);
                
            });
            //*/
        }
        else
        {
            if(!plugin)
            {
                var httpRequest = $http({
                    method: 'GET',
                    url: "/online/search/"+$send.source+"/"+$id+"/"+$send.page
                })
                .then(function successCallback(response) 
                {
                    $scope.templateURL='video_search_result.tpl';
                    $scope.videos=$videos.concat(response.data);
                }, 
                function errorCallback(response) 
                {
                    console.log(response);
                });
            }
            else
            {
                source =_.findWhere($scope.config.source, {id: $send.source});
                UberSource.setSource(source.name).search($('.serial-title').text(), $send.page)
                    .then(function(data)
                    {
                        console.log(data);
                        $scope.videos=$videos.concat(data);
                        $scope.templateURL='video_search_result.tpl';
                    });
            }
        }
        
    };

    $scope.play = function($key) {
        
    };

    $scope.setPlay = function($url, $send, $event=false) {
        if(!plugin)
        {
            window.open($url, '_blank');
        }
        else
        {
            if($event)
            {
                console.log($event);
            }
            source =_.findWhere($scope.config.source, {id: $send.source});
            return UberSource.setSource(source.name).page($url)
                .then(function(data)
                {
                    console.log(data);
                    if(!data)
                    {
                        userFa.videoDelete($url);
                        return true;
                    }
                    if(myPlayer)
                    {
                        myPlayer.dispose();
                        myPlayer=false;
                    }
                    $scope.video=data;
                    return false;
                });
            
        }
    };

});

SovTor.controller('SubtitleCtrl', function($scope, $http, TD, UberSource) 
{
    TD.get('ep').then(function(data){
        $scope.ep=data;
    });
    $scope.send = {
                id: 0,
                source: 10,
                lang: 1,
                page: 0,
                season: 0,
                ep: 0,
            };
    $scope.results =  [];

    $scope.search = function($send, $result=[]) {
        lang =_.findWhere($scope.config.lang, {id: $send.lang});
        if(!plugin)
        {
            var httpRequest = $http({
                method: 'GET',
                url: "/subtitle/search/"+$send.source+"/"+lang.opensubtitles_short+"/"+$send.id+"/"+$send.page+"/"+$send.season+"/"+$send.ep
            })
            .then(function successCallback(response) 
            {
                $scope.results=$result.concat(response.data);
            }, 
            function errorCallback(response) 
            {
                console.log(response);
            });
        }
        else
        {
            
            source =_.findWhere($scope.config.source, {id: $send.source});
            UberSource.setSource(source.name).search($('.serial-title').text(), $send.season, $send.ep, lang.opensubtitles_short )
            .then(function(data)
            {
                console.log(data);
                $scope.results=$result.concat(data);
            });
        }
    };
});

SovTor.controller('TorrentCtrl', function($scope, $http, TD, UberSource, $rootScope, $filter) 
{
    $scope.send = {
                source: 0,
                gquality: 0,
                lang: 0,
                page: 0,
            };
    $scope.order = 'size';
    $scope.limit = 10;
    $scope.step = 10;
    $scope.reverse = true;
    TD.get('torrent').then(function(data){
        $rootScope.torrent=data;
    });
    $scope.search = function($send, $id, $result=[]) {
        if(!plugin)
        {
            var httpRequest = $http({
                method: 'GET',
                url: "/torrent/search/"+$send.source+"/"+$send.gquality+"/"+$send.lang+"/"+$id+"/"+$send.page
            })
            .then(function successCallback(response) 
            {
                $rootScope.torrent=$result.concat(response.data);
            }, 
            function errorCallback(response) 
            {
                console.log(response);
            });
        }
        else
        {
            source =_.findWhere($scope.config.source, {id: $send.source});
            lang =_.findWhere($scope.config.lang, {id: $send.lang});
            
            UberSource.setSource(source.name).search($('.serial-title').text(), $send.page)
            .then(function(data)
            {
                console.log(data);
                $rootScope.torrent=$result.concat(data);
            });
        }
    };
    
    $scope.filters = function(torrent) 
    {
        if($scope.send.source && $scope.send.source!=torrent.source_id)
        {
            return false;
        }
        
        if($scope.send.gquality && $scope.send.gquality!=torrent.quality.quality_group_id)
        {
            return false;
        }
        
        if($scope.send.lang && $scope.send.lang!=torrent.lang_id)
        {
            return false;
        }
        return true;
    };
    
    $scope.sortBy = function(propertyName) 
    {
        $scope.reverse = ($scope.order === propertyName) ? !$scope.reverse : false;
        $scope.order = propertyName;
    };
    
    $scope.uniq = function(torrent) 
    {
        torrent=$filter('unique')(torrent, 'id');
        return torrent;
    };
    
    $scope.incrementLimit = function() 
    {
        $scope.limit+=$scope.step;
        //return torrent;
    };
});

SovTor.controller('SearchCtrl', function($scope, $http, $window, $timeout, localStorageService) 
{
    if(!localStorageService.get('send'))
    {
        localStorageService.set('send', {'torrent':1});
    }
    localStorageService.bind($scope, 'send', localStorageService.get('send'));
    
    $scope.$watch('send', function (newVal, oldVal) 
    {
        localStorageService.set('send', newVal);
    }, true);

    $scope.serials = [];
    $scope.eps = [];
    $scope.titles = [];
    $scope.send = getAllUrlParams();
    if ($scope.send.title !== undefined) {
        $scope.send.title = decodeURI($scope.send.title);
    }
    if ($scope.send.start !== undefined) {
        $scope.send.start = new Date($scope.send.start);
    }
    if ($scope.send.end !== undefined) {
        $scope.send.end = new Date($scope.send.end);
    }
    $scope.loading = false;
    $scope.match=function (item) {
        window.location= "/serial/"+item.id;
    };

    $scope.searchTop=function () 
    {
        window.location= "/search/serial?title="+encodeURIComponent($scope.send.title);
    };

    $scope.search=function (limit=30,pushstate=true) 
    {
        
        if(!$scope.loading)
        {
            $scope.loading = true; 
            var send=$scope.send;
            send.limit=limit;
            console.log(send);
            //console.log($scope.send.start_date);
            //console.log($scope.send.end_date);
            if(send.start)
            {
                send.start=moment(send.start).format('YYYY-MM-DD');
            }
            
            if(send.end)
            {
                send.start=moment(send.start).format('YYYY-MM-DD');
            }
            
            
            var str=jQuery.param( send );
            //var url= window.location.origin+window.location.pathname+'?'+str;
            var url= $scope.url+'?'+str;
            return $http({
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                url: url
            })
            .then(function successCallback(response) 
            {
                angular.element('.static').remove();
                $scope.serials =response.data.serials;
                $scope.eps =response.data.eps;
                //$scope.$parent.eps=response.data.eps;
                $scope.loading = false; 
                if(pushstate)
                {
                    $window.history.pushState(url, $scope.send.title, url);
                }
                return response.data.serials;
            }, 
            function errorCallback(response) 
            {
                console.log(response);
            });
        }
    };

});

SovTor.controller('SerialCtrl', function($scope, TD, $rootScope) 
{
    TD.get('ep').then(function(data){
        $scope.ep=data;
    });

    $scope.eps = [];
    TD.get('serial').then(function(data){
        $scope.serial=data;
        angular.element('.jumbotron.mein')
            .css("background-image", 'url(https://fileom.s3.amazonaws.com/'+$scope.serial.poster.path+'/max/'+$scope.serial.poster.name)
            ;
        console.log(data);
        $scope.serial.people.forEach(function (item,i, arr) {
            $scope.serial.character.forEach(function (char,i, arr) {
                if(item.pivot.character_id==char.id)
                {
                    item.character=char;
                }
            });
        });

    });
    
    $scope.pushTorrent=function(torrent)
    {
        $rootScope.torrent.push(torrent);
    }
    
    $scope.log=function(data)
    {
        console.log(data);
    }
});

SovTor.controller('DateCtrl', function($scope, $filter, $http, TD) 
{
    $scope.date = new Date();
    TD.get('eps').then(function(data){
        $scope.eps=data;
    });
        
    $scope.getDate = function(date) {
        $scope.date = new Date(date);
        pre_date = $filter('date')(date, 'yyyy-MM-dd');
        var httpRequest = $http({
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            url: "/ep/date/"+pre_date
        })
        .then(function successCallback(response) 
        {
            //console.log(response);
            $scope.eps =response.data.eps;
        }, 
        function errorCallback(response) 
        {
            console.log(response);
        });
    };
    
});